import React, { useEffect, useState } from 'react'
import EventCard from '../../components/EventCard';
import ContentLoader from 'react-content-loader'

const HomeEvents = ({ data, loading }) => {


  return (
    <>
      <div className="usage-container py-md-3 px-md-5 py-5 px-2">
        <div className="row g-0">
          <div className="col pt-md-5">
            <h2 className="usage-title text-center">
              Everyday is a<span className='color'> D-day  </span>at  <span className='color'>KonfHub</span>
            </h2>
            <div className='sub-text text-center mx-auto'>
              No more dull days! Check out our exciting event line-up and register for upcoming events
            </div> 
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <div className="d-flex flex-wrap featured-cards mt-4 pt-2 justify-content-center align-items-center">
              {
                loading ?
                ['', '', ''].map(()=>(
                  <>
                    {/* <div className="card conference_card mt-4 mb-5" aria-hidden="true">
                        <div className="card-img-top-hidden" style={{width: 290, height: 145}} />
                        <div className="card_content">
                            <div className="eventcard-body p-3">
                                <h5 className="card-title text-start placeholder-glow">
                                <span className="placeholder col-6"></span>
                                </h5>
                                <p className="card-text mt-3 text-start placeholder-glow">
                                    <span className="placeholder col-11"></span>
                                    <span className="placeholder col-3"></span>
                                    <span className="placeholder col-6"></span>
                                    <span className="placeholder col-8"></span>
                                </p>
                            </div>
                        </div>
                    </div> */}
                    <div className=''>
                      <ContentLoader viewBox="0 0 300 350" height={350} width={300}>
                        <rect x="0" y="0" rx="5" ry="5" width="300" height="170" />
                        <rect x="0" y="187" rx="4" ry="4" width="300" height="20" />
                        <rect x="0" y="217" rx="4" ry="4" width="270" height="20" />
                        <rect x="0" y="247" rx="4" ry="4" width="240" height="20" />
                        <rect x="0" y="277" rx="4" ry="4" width="100" height="20" />
                      </ContentLoader>
                    </div>
                  </>
                )) 
              :
                data.length > 0 ? data.slice(0, 4).map((event, idx) => (
                  <EventCard
                    id={idx}
                    poster_url={event.event_poster_url}
                    category={event.category}
                    name={event.event_name}
                    logo_url={event.organiser_img}
                    startDate={event.event_start_timestamp}
                    endDate={event.event_end_timestamp}
                    country={event.country}
                    venue={event.venue}
                    is_free={event.is_free}
                    modeType={event.is_virtual}
                    city={event.city}
                    users={event.expected_participants}
                    event_url={event.event_url}
                    loading={loading}
                  />
                ))
                :
                <div className="mt-5 mb-5 pb-5">
                    There are no featured events at the moment
                </div>
              }
            </div>
          </div>
          {data.length > 4 && (
            <div className='d-flex justify-content-center'>
              <a href={`/events`} target="_blank" rel="noreferrer">
                <button className="btn btn-header mb-lg-2 mt-4 btn-styles ms-0">
                  Explore
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
      <style>{`
        .usage-container {
            background: #f6f9fc;
        }

        .usage-container .usage-title {
            color: #572148;
            font-weight: 800;
            font-size: 50px;
            font-family: "Nunito";
        }
        .color{
            color: #fb5850;
        }
        .featured-cards{
          gap: 40px;
        }
        .conference_card {
          height: 315px !important;
          width: 290px;
          border-radius: 6px;
          background-color: white;
          overflow: hidden;
          cursor: pointer;
          position: relative;
      }
        .sub-text{
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
            color: #4F4F4F;
        }
        .btn-styles{
          height: 35px;
          display: flex;
          align-items: center;
        }

        .featured-cards{
          flex-direction: row !important;
        }
        .card-img-top-hidden{
          height: 200px;
          background: rgb(134, 142, 150);
        }

        @media only screen and (min-width: 480px) and (max-width: 768px) {
            .usage-container .usage-title {
                font-size: 35px;
            }
        }

        @media only screen and (min-width: 480px) and (max-width: 992px) {
            .featured-cards{
              flex-direction: column !important;
            }
        }



        @media only screen and (max-width: 480px) {
            .usage-container .usage-title {
                font-size: 35px;
            }
            .featured-cards{
              flex-direction: column !important;
            }
        }
    `}</style>
    </>
  )
}

export default HomeEvents
